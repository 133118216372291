// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Typography } from '@mui/material';
import { staticEndPoints } from '../config';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;
  const LOGO = '</>';
  const COMPANY_NAME = 'ScaleHorizon';

  const endPoint =
    process.env.NODE_ENV === 'production'
      ? staticEndPoints.prodEndPoint
      : staticEndPoints.devEndPoint;

  return (
    <Box sx={{ ...sx }}>
      <Box
        component="img"
        sx={{
          height: 65,
          width: 150,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        src={`${endPoint}assets/images/home/CompanyLogo.svg`}
      />
    </Box>
  );
}
